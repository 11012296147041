import { createContext } from "react";
import {
  Observable,
  scan,
  switchMap,
  Observer,
  map as omap,
  merge,
  shareReplay,
  combineLatest,
  debounceTime,
  concatMap,
  from,
  of,
  concat,
  lastValueFrom,
  ReplaySubject,
  mergeScan,
  timeout,
  retry,
} from "rxjs";
import { Contract } from "web3-eth-contract";
import * as t from "io-ts";
import { PathReporter } from "io-ts/PathReporter";
import { isLeft, map } from "fp-ts/Either";
import {
  HTMLUpdatedTransaction,
  TileInfo,
  TileSoldTransaction,
} from "../types";
import {
  fromTileId,
  getLast,
  getOrSetDefault,
  tileId,
  updateMap,
} from "../util";
import { inflate, deflate } from "pako";
import { Base65536 } from "../util/base65536";
import Web3 from "web3";
import { TileUpdatedTopic } from "../constants";

const VERY_SPECIAL_CHAR = "߰";

const BigIntFromString = new t.Type(
  "BigIntFromString",
  (v): v is bigint => v instanceof BigInt,
  (s: unknown) => map((s: string) => BigInt(s))(t.string.decode(s)),
  (v) => v.toString()
);
const SoldTile = t.type(
  {
    page: BigIntFromString,
    x: BigIntFromString,
    y: BigIntFromString,
    from: t.string,
    to: t.string,
    price: BigIntFromString,
  },
  "CornerEvent"
);
type SoldTile = t.TypeOf<typeof SoldTile>;
type AugmentedSoldTile = SoldTile & {
  transactionHash: string;
  blockNumber: number;
};
type AugmentedUpdatedTile = UpdatedTile & {
  transactionHash: string;
  blockNumber: number;
};
type RawEvent = {
  raw: { data: string; topics: string[] };
  transactionHash: string;
  returnValues: unknown;
  blockNumber: number;
};
type PageReturn = { html: string; price: bigint; owner: string };
export type HistoricalData = {
  htmlHistory: HTMLUpdatedTransaction[];
  transactions: TileSoldTransaction[];
};
class TilesBloc {
  public readonly $cornerContract: Observer<Contract>;
  private cornerContract$: Observable<Contract>;
  private web3$: Observable<Web3>;
  public readonly $web3: Observer<Web3>;
  public readonly tiles$: Observable<TileInfo[]>;
  public readonly tileMap$: Observable<Map<bigint, TileInfo>>;
  private historicalDataMap = new Map<bigint, Observable<HistoricalData>>();
  constructor() {
    const $cornerContract$ = new ReplaySubject<Contract>(1);
    this.$cornerContract = $cornerContract$;
    this.cornerContract$ = $cornerContract$;

    const $web3$ = new ReplaySubject<Web3>(1);
    this.$web3 = $web3$;
    this.web3$ = $web3$;

    this.tileMap$ = combineLatest([$cornerContract$, $web3$]).pipe(
      switchMap(([cornerContract, web3]) =>
        from(
          (async () => {
            const latestBlock = await web3.eth.getBlockNumber();
            const events: RawEvent[] = await cornerContract.getPastEvents(
              "SoldTile",
              { fromBlock: 0, toBlock: latestBlock }
            );
            const tiles = new Map<
              bigint,
              {
                dataPromise: Promise<PageReturn>;
                lastSoldPrice: number;
                numberOfTransactions: number;
                lastTransactionHash: string;
              }
            >();
            for (const event of events) {
              const maybeEvent = SoldTile.decode(event.returnValues);

              if (isLeft(maybeEvent)) {
                throw new Error(PathReporter.report(maybeEvent).join("\n"));
              }
              const { page, x, y, price } = maybeEvent.right;
              const id = tileId(page, x, y);
              updateMap(
                tiles,
                id,
                () => ({
                  dataPromise: cornerContract.methods
                    .pages(page, x, y)
                    .call() as Promise<PageReturn>,
                  lastSoldPrice: 0,
                  lastTransactionHash: "",
                  numberOfTransactions: 0,
                }),
                (data) => {
                  data.lastSoldPrice = Number(price) / 1e18;
                  data.numberOfTransactions++;
                  data.lastTransactionHash = event.transactionHash;
                  return data;
                }
              );
            }
            return {
              latestBlock,
              tiles: merge(
                ...[...tiles.entries()].map(
                  async ([
                    id,
                    {
                      dataPromise,
                      lastSoldPrice,
                      lastTransactionHash,
                      numberOfTransactions,
                    },
                  ]) => {
                    const { page, x, y } = fromTileId(id);
                    const { html, ...rest } = await dataPromise;
                    return {
                      ...rest,
                      html: decodeTileHtml(html),
                      page,
                      x,
                      y,
                      lastSoldPrice,
                      lastTransactionHash,
                      numberOfTransactions,
                    };
                  }
                )
              ),
            };
          })()
        ).pipe(
          switchMap(({ latestBlock, tiles }) => {
            const initialState = tiles.pipe(
              scan((acc, info) => {
                const { page, x, y, price } = info;
                acc.set(tileId(page, x, y), {
                  ...info,
                  priceEth: Number(price) / 1e18,
                });
                return acc;
              }, new Map<bigint, TileInfo>()),
              shareReplay(1)
            );
            return concat(
              initialState,
              from(lastValueFrom(initialState)).pipe(
                switchMap((state) => {
                  return getAllEvents$(cornerContract, web3, latestBlock).pipe(
                    scan<
                      AugmentedSoldTile | AugmentedUpdatedTile,
                      Map<bigint, TileInfo>
                    >((tiles, event) => {
                      const { page, x, y } = event;
                      updateMap(
                        tiles,
                        tileId(page, x, y),
                        () => ({
                          html: "",
                          owner: "",
                          page,
                          x: Number(x),
                          y: Number(y),
                          price: BigInt(0),
                          priceEth: 0,
                          lastSoldPrice: 0,
                          numberOfTransactions: 0,
                          lastTransactionHash: "",
                        }),
                        (entry) => {
                          if (isUpdatedTile(event)) {
                            entry.html = decodeTileHtml(event.html);
                            entry.price = event.price;
                            entry.priceEth = Number(event.price) / 1e18;
                          } else {
                            entry.owner = event.to;
                            entry.numberOfTransactions++;
                            entry.lastSoldPrice = Number(event.price) / 1e18;
                            entry.lastTransactionHash = event.transactionHash;
                          }
                          return entry;
                        }
                      );
                      return tiles;
                    }, state)
                  );
                })
              )
            );
          })
        )
      ),
      debounceTime(100),
      timeout({ first: 10000 }),
      retry(),
      shareReplay({ bufferSize: 1, refCount: false })
    );
    this.tiles$ = this.tileMap$.pipe(omap((m) => [...m.values()]));
  }
  public getHistoricalData(
    page: bigint,
    x: number,
    y: number
  ): Observable<HistoricalData> {
    return getOrSetDefault(this.historicalDataMap, tileId(page, x, y), () => {
      return combineLatest([this.cornerContract$, this.web3$]).pipe(
        switchMap(([cornerContract, web3]) =>
          getAllEvents$(cornerContract, web3, 0).pipe(
            mergeScan(
              (acc, event) =>
                from(
                  (async () => {
                    // fuzzy equals to ignore number/bigint
                    if (page != event.page || x != event.x || y != event.y) {
                      return acc;
                    }

                    if (isUpdatedTile(event)) {
                      let html = decodeTileHtml(event.html);
                      if (getLast(acc.htmlHistory)?.html !== html) {
                        const { timestamp } = await web3.eth.getBlock(
                          event.blockNumber
                        );
                        acc.htmlHistory.push({
                          type: "updated",
                          html: decodeTileHtml(event.html),
                          owner: event.owner,
                          date: new Date(Number(timestamp) / 1000),
                        });
                      }
                    } else {
                      const { timestamp } = await web3.eth.getBlock(
                        event.blockNumber
                      );
                      acc.transactions.push({
                        type: "sold",
                        hash: event.transactionHash,
                        from: event.from,
                        price: Number(event.price) / 1e18,
                        to: event.to,
                        date: new Date(Number(timestamp) / 1000),
                      });
                    }
                    return acc;
                  })()
                ),
              {
                htmlHistory: [],
                transactions: [],
              } as HistoricalData,
              1
            ),
            debounceTime(100)
          )
        ),
        timeout({ first: 10000 }),
        retry(),
        shareReplay({ bufferSize: 1, refCount: false })
      );
    });
  }
}

export const TilesContext = createContext<TilesBloc>(new TilesBloc());

export function encodeTileHtml(html: string) {
  const tryEncode =
    VERY_SPECIAL_CHAR + Base65536.encode(deflate(html, { level: 9 }));
  if (
    new TextEncoder().encode(tryEncode).length <
    new TextEncoder().encode(html).length
  ) {
    return tryEncode;
  } else {
    return html;
  }
}
function decodeTileHtml(encoded: string): string {
  try {
    if (encoded.startsWith(VERY_SPECIAL_CHAR)) {
      return inflate(Base65536.decode(encoded.slice(1)), {
        to: "string",
      });
    } else {
      return encoded;
    }
  } catch {
    return encoded;
  }
}
const getAllEvents$ = (
  cornerContract: Contract,
  web3: Web3,
  fromBlock: number
): Observable<AugmentedUpdatedTile | AugmentedSoldTile> =>
  new Observable<RawEvent>((subscriber) => {
    cornerContract.events.allEvents({ fromBlock }, (error: any, data: any) => {
      if (error) {
        return subscriber.error(error);
      }
      subscriber.next(data);
    });
  }).pipe(
    concatMap((data) => {
      if (data.raw.topics.includes(TileUpdatedTopic)) {
        return of({
          ...decodeTileUpdatedEvent(data.raw.data, web3),
          transactionHash: data.transactionHash,
          blockNumber: data.blockNumber,
        });
      } else {
        const txPromise = web3.eth.getTransaction(data.transactionHash);
        const maybeEvent = SoldTile.decode(data.returnValues);

        if (isLeft(maybeEvent)) {
          throw new Error(PathReporter.report(maybeEvent).join("\n"));
        }
        return concat(
          of({
            ...maybeEvent.right,
            transactionHash: data.transactionHash,
            blockNumber: data.blockNumber,
          }),
          from(
            txPromise.then((tx) => {
              let html = decodeEthString(tx.input, 10 + size * 4, web3);
              const { page, x, y, to } = maybeEvent.right;
              return {
                html,
                page,
                x: Number(x),
                y: Number(y),
                owner: to,
                price: BigInt(0),
                transactionHash: data.transactionHash,
                blockNumber: data.blockNumber,
              };
            })
          )
        );
      }
    })
  );

type UpdatedTile = {
  page: bigint;
  x: number;
  y: number;
  price: bigint;
  owner: string;
  html: string;
};
const isUpdatedTile = (
  e: AugmentedUpdatedTile | AugmentedSoldTile
): e is AugmentedUpdatedTile => (e as UpdatedTile).html !== undefined;
const decoder = new TextDecoder();

const size = 64;
const zx = "0x";
function decodeTileUpdatedEvent(data: string, web3: Web3): UpdatedTile {
  let i = 2;

  const page = web3.eth.abi.decodeParameter(
    "uint256",
    zx + data.substr(i, size)
  ) as unknown as bigint;

  i += size;
  const x = Number(
    web3.eth.abi.decodeParameter("uint256", zx + data.substr(i, size))
  );

  i += size;
  const y = Number(
    web3.eth.abi.decodeParameter("uint256", zx + data.substr(i, size))
  );

  i += size;
  const owner = web3.eth.abi.decodeParameter(
    "address",
    zx + data.substr(i, size)
  ) as unknown as string;

  i += size;
  // skip special 192
  i += size;
  const price = web3.eth.abi.decodeParameter(
    "uint256",
    zx + data.substr(i, size)
  ) as unknown as bigint;

  i += size;
  const html = decodeEthString(data, i, web3);

  return {
    page,
    x,
    y,
    owner,
    price,
    html,
  };
}

function decodeEthString(hex: string, start: number, web3: Web3) {
  const length = Number(
    web3.eth.abi.decodeParameter("uint256", zx + hex.substr(start, size))
  );
  const b = Buffer.from(hex.substr(start + size, length * 2), "hex");
  return decoder.decode(b);
}
