import { ReactNode, useState, ReactElement, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import { debounceTime, Subject } from "rxjs";
import { tileHeight, tileWidth } from "../constants";

enum Source {
  Twitter,
  Youtube,
  Image,
  Video,
}
const SourceButton = ({
  activeClasses,
  source,
  currentSource,
  inactiveClasses,
  onClick,
  children,
}: {
  activeClasses: string;
  inactiveClasses: string;
  source: Source;
  currentSource: Source;
  onClick: (source: Source) => any;
  children: ReactNode;
}) => {
  return (
    <div
      onClick={source !== currentSource ? () => onClick(source) : undefined}
      className={`rounded-md filter brightness-100 p-2 border-2 w-20 flex flex-row justify-around items-center mx-2 ${
        source !== currentSource
          ? activeClasses +
            " cursor-pointer hover:brightness-90 active:brightness-75 border-transparent"
          : inactiveClasses + " border-opacity-100"
      }`}
    >
      {children}
    </div>
  );
};
type SourceEditor = (props: {
  onChange: (html: string) => any;
}) => ReactElement;
const ytRegex =
  /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
const editors: Record<Source, SourceEditor> = {
  [Source.Youtube]: ({ onChange }) => {
    const $errors$ = useMemo(() => {
      return new Subject<string>();
    }, []);
    useEffect(() => {
      const subscription = $errors$.pipe(debounceTime(500)).subscribe((err) => {
        toast.warn(err);
      });
      return () => subscription.unsubscribe();
    }, []);
    return (
      <input
        onChange={(e) => {
          const result = ytRegex.exec(e.target.value);
          if (!result || !result[1]) {
            $errors$.next("That doesn't look like a YouTube URL");
            return;
          }
          onChange(
            `<style>*{margin:0}</style><iframe frameborder="0"width="${tileWidth}"height="${tileHeight}"src="https://www.youtube.com/embed/${result[1]}">`
          );
        }}
        className="rounded-md p-1 bg-darkblue"
        placeholder="YouTube link"
        type="text"
      ></input>
    );
  },
  [Source.Twitter]: ({ onChange }) => {
    return (
      <input
        onChange={(e) => {
          const handle = e.target.value;
          const strippedHandle = handle.startsWith("@")
            ? handle.substr(1)
            : handle;
          onChange(
            `<style>*{margin:0}::-webkit-scrollbar{width: 8px;}::-webkit-scrollbar-button{display:none;}::-webkit-scrollbar-thumb{border-radius:4px;background-color:aqua;}</style><a class="twitter-timeline"href="https://twitter.com/${strippedHandle}?ref_src=twsrc%5Etfw">Tweets by ${strippedHandle}</a><script async src="https://platform.twitter.com/widgets.js"charset="utf-8"></script>`
          );
        }}
        className="rounded-md p-1 bg-darkblue"
        placeholder="Twitter handle"
        type="text"
      ></input>
    );
  },
  [Source.Image]: ({ onChange }) => {
    const [link, setLink] = useState("");
    const [img, setImg] = useState("");
    useEffect(() => {
      if (img) {
        onChange(
          `<style>*{margin:0}a{display:inline-block}img{object-fit:contain}</style>${
            link ? `<a href="${link}">` : ""
          }<img src="${img}"width="${tileWidth}"height="${tileHeight}"></img>${
            link ? "</a>" : ""
          }`
        );
      }
    }, [link, img]);
    return (
      <div className="flex flex-col justify-evenly items-center">
        <input
          onChange={(e) => {
            setImg(e.target.value);
          }}
          className="rounded-md mb-3 p-1 bg-darkblue"
          placeholder="Image URL"
          type="text"
        ></input>
        <input
          onChange={(e) => {
            setLink(e.target.value);
          }}
          className="rounded-md p-1 bg-darkblue"
          placeholder="Link"
          type="text"
        ></input>
      </div>
    );
  },
  [Source.Video]: ({ onChange }) => {
    const [link, setLink] = useState("");
    const [src, setSrc] = useState("");
    useEffect(() => {
      if (src) {
        onChange(
          `<style>*{margin:0}a{display:inline-block}video{object-fit:contain}</style>${
            link ? `<a href="${link}">` : ""
          }<video controls src="${src}"width="${tileWidth}"height="${tileHeight}"></img>${
            link ? "</a>" : ""
          }`
        );
      }
    }, [link, src]);
    return (
      <div className="flex flex-col justify-evenly items-center">
        <input
          onChange={(e) => {
            setSrc(e.target.value);
          }}
          className="rounded-md mb-3 p-1 bg-darkblue"
          placeholder="Video URL"
          type="text"
        ></input>
        <input
          onChange={(e) => {
            setLink(e.target.value);
          }}
          className="rounded-md p-1 bg-darkblue"
          placeholder="Link"
          type="text"
        ></input>
      </div>
    );
  },
};

type Props = {
  onChange: (html: string) => any;
};
export const HTMLHelper = ({ onChange }: Props) => {
  const [source, setSource] = useState(Source.Twitter);
  const Editor = editors[source];
  return (
    <div className="m-4 flex justify-around bg-gradient-to-r from-clearblue to-darkblue p-1 rounded-2xl">
      <div className="flex flex-col text-white p-1  rounded-xl bg-notsodark w-full">
        <div className="flex flex-row justify-evenly font-bold text-xl m-1">
          EASY EDITOR
        </div>
        <div className=" flex flex-row justify-evenly my-2">
          <SourceButton
            source={Source.Youtube}
            currentSource={source}
            activeClasses="bg-red-700"
            inactiveClasses="text-red-700 border-red-700"
            onClick={setSource}
          >
            YouTube
          </SourceButton>
          <SourceButton
            source={Source.Twitter}
            currentSource={source}
            activeClasses="bg-blue-500"
            inactiveClasses="text-blue-500 border-blue-500"
            onClick={setSource}
          >
            Twitter
          </SourceButton>
          <SourceButton
            source={Source.Image}
            currentSource={source}
            activeClasses="text-black bg-white"
            inactiveClasses="text-white border-white"
            onClick={setSource}
          >
            Image
          </SourceButton>
          <SourceButton
            source={Source.Video}
            currentSource={source}
            activeClasses="text-white bg-black"
            inactiveClasses="text-white border-black"
            onClick={setSource}
          >
            Video
          </SourceButton>
        </div>
        <div className="flex flex-row justify-around m-2">
          <Editor onChange={onChange}></Editor>
        </div>
      </div>
    </div>
  );
};
