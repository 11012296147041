export type TileSoldTransaction = {
  type: "sold";
  from: string;
  to: string;
  price: number;
  hash: string;
  date: Date;
};
export type HTMLUpdatedTransaction = {
  type: "updated";
  owner: string;
  html: string;
  date: Date;
};
export type TileTransaction = TileSoldTransaction | HTMLUpdatedTransaction;
export type TileInfo = {
  html: string;
  x: number;
  y: number;
  page: bigint;
  priceEth: number;
  price: bigint;
  owner: string;
  lastSoldPrice: number;
  numberOfTransactions: number;
  lastTransactionHash: string;
};

export enum Chain {
  Mainnet,
  Ropsten,
}

export enum ErrorType {
  NO_METAMASK,
  NO_ETH_PRICE,
}

export type NoMetamaskError = {
  type: ErrorType.NO_METAMASK;
};
export type NoEthPriceError = {
  type: ErrorType.NO_ETH_PRICE;
};

export type MBCError = NoMetamaskError | NoEthPriceError;
