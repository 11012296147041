import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { clearblue, darkblue } from "../constants";
export type PriceHistoryPoint = {
  date: Date;
  price: number;
  hash: string;
  from: string;
  to: string;
};
type Props = {
  data: PriceHistoryPoint[];
  etherscanURL: string;
};
export const PriceHistory = ({ data, etherscanURL }: Props) => {
  const points = data.map(({ date, price }, i) => ({
    x: date.getTime(),
    y: price,
  }));
  return (
    <Line
      getElementAtEvent={([element]: any[]) => {
        if (element && element.index !== undefined) {
          window
            .open(`${etherscanURL}/tx/${data[element.index].hash}`, "_blank")!
            .focus();
        }
      }}
      height={150}
      data={{
        datasets: [
          {
            data: points,
            pointRadius: 7,
            pointHitRadius: 15,
            hoverRadius: 5,
            cubicInterpolationMode: "monotone",
            backgroundColor: clearblue,
            borderColor: clearblue,
            parsing: false,
            label: "PRICE HISTORY",
          },
        ],
      }}
      options={{
        interaction: { mode: "nearest", axis: "x", intersect: true },
        onHover(e, elements) {
          const style = (e.native?.target as HTMLElement | undefined)?.style;
          if (style) {
            style.cursor = elements[0] ? "pointer" : "default";
          }
        },
        plugins: {
          legend: {
            display: true,
            fullSize: true,
            labels: { color: clearblue },
          },
          tooltip: {
            position: "nearest",
            displayColors: false,
            backgroundColor: darkblue,
            caretSize: 0,
            xAlign: "center",
            yAlign: "bottom",
            callbacks: {
              label: (item) => {
                const { from, to, price } = data[item.dataIndex];
                if (from) {
                  return [`${price}Ξ ${from}`, `sold to ${to}`];
                } else {
                  return `${price}Ξ bought by ${to} `;
                }
              },
            },
          },
        },
        scales: {
          y: {
            grid: { drawBorder: false, color: darkblue },
            display: true,
            ticks: { color: clearblue },
          },
          x: {
            grid: { drawTicks: false },
            type: "timeseries",
            ticks: { color: clearblue, source: "data" },
            time: { minUnit: "day" },
          },
        },
      }}
    />
  );
};
