import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { combineLatest, Subject, throttleTime } from "rxjs";
import { BigPrice } from "../components/BigPrice";
import { Button } from "../components/Button";
import { Header } from "../components/Header";
import { HTMLHelper } from "../components/HTMLHelper";
import { PriceHistory } from "../components/PriceHistory";
import { Tile } from "../components/Tile";
import { Timeline } from "../components/Timeline";
import { ethPrice$ } from "../data_sources/ethPrice";
import {
  encodeTileHtml,
  HistoricalData,
  TilesContext,
} from "../data_sources/tiles";
import { ProviderType, Web3Context } from "../data_sources/web3";
import { observerHandler, tileId, useSubscription } from "../util";
import anime from "animejs";
import { TileInfo } from "../types";
import { Contract } from "web3-eth-contract";

type Props = {
  page: bigint;
  x: number;
  y: number;
};

export const TileOverview = ({ page, x, y }: Props) => {
  const tilesBloc = useContext(TilesContext);
  const web3Bloc = useContext(Web3Context);

  type S = [
    Map<bigint, TileInfo> | undefined,
    string[],
    ProviderType,
    Contract | undefined
  ];
  const [tiles, accounts, providerType, cornerContract] = useSubscription<S, S>(
    () =>
      combineLatest([
        tilesBloc.tileMap$,
        web3Bloc.accounts$,
        web3Bloc.providerType$,
        web3Bloc.cornerContract$,
      ]),
    [undefined, [], ProviderType.Backend, undefined],
    undefined,
    undefined,
    [tilesBloc, web3Bloc]
  );
  const [interactedWithTimeline, setInteractedWithTimeline] = useState(false);

  const info = tiles?.get(tileId(page, x, y));
  const { htmlHistory, transactions } = useSubscription<
    HistoricalData,
    HistoricalData
  >(
    () => tilesBloc.getHistoricalData(page, x, y),
    {
      htmlHistory: [],
      transactions: [],
    },
    undefined,
    undefined,
    [page, x, y]
  );

  const isOwner = info && accounts.includes(info.owner);
  const forSale = info !== undefined && info.priceEth !== 0;
  const [editing, setEditing] = useState(false);
  const [newPrice, setNewPrice] = useState<number | undefined>(undefined);
  const etherscanURL = useSubscription(web3Bloc.etherscanURL$, "");
  const tref = useRef<HTMLDivElement>(null);

  const [$noEditMessage$, $editingHTML$] = useMemo(
    () => [new Subject(), new Subject<string>()],
    []
  );
  const throttledEditingHTML = useSubscription(
    () =>
      $editingHTML$.pipe(
        throttleTime(1000, undefined, { leading: true, trailing: true })
      ),
    "",
    undefined,
    undefined,
    [$editingHTML$]
  );
  const editingHTML = useSubscription(
    () => $editingHTML$,
    "",
    undefined,
    undefined,
    [$editingHTML$]
  );
  const [timelineIndex, setTimelineIndex] = useState<number | undefined>(
    undefined
  );

  const historyLength = htmlHistory.length;
  useEffect(() => {
    if (historyLength > 0) {
      setTimelineIndex(historyLength - 1);
    } else {
      setTimelineIndex(undefined);
    }
  }, [historyLength, setTimelineIndex]);

  const [tileWidth, setTileWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      if (tref.current) {
        setTileWidth(tref.current.clientWidth);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    $noEditMessage$.pipe(throttleTime(5000)).subscribe(() => {
      toast.warn(
        "If you want to purchase the tile and edit the HTML click the START PURCHASE button first",
        { autoClose: 5000, pauseOnFocusLoss: false }
      );
    });
  }, [$noEditMessage$]);

  useEffect(() => {
    anime
      .timeline({
        duration: 400,
        loop: false,
        direction: "normal",
        easing: "easeOutQuint",
      })
      .add(
        {
          targets: "#ez",
          translateX: editing ? 0 : 1000,
        },
        editing ? 200 : 0
      )
      .add(
        {
          targets: "#chart",
          translateY: editing ? 1000 : 0,
        },
        editing ? 0 : 200
      );
  }, [editing]);

  return (
    <div className="h-full flex flex-col ">
      <Header />
      <div className="flex flex-row  ml-20 flex-grow overflow-hidden">
        <div className="flex flex-col h-full w-1/2">
          <div className="bg-dark my-4 p-3 rounded-lg w-full transition-all">
            <div ref={tref} className="w-full">
              <Tile
                etherscanURL={etherscanURL}
                info={
                  !info
                    ? undefined
                    : editing
                    ? { ...info, html: throttledEditingHTML }
                    : {
                        ...info,
                        html:
                          timelineIndex !== undefined &&
                          interactedWithTimeline &&
                          htmlHistory[timelineIndex]
                            ? htmlHistory[timelineIndex].html
                            : info.html,
                      }
                }
                width={tileWidth}
              ></Tile>
            </div>
            {info ? (
              historyLength > 1 ? (
                <Timeline
                  data={htmlHistory}
                  selectedIndex={timelineIndex || 0}
                  onChange={(newIndex) => {
                    setEditing(false);
                    setTimelineIndex(newIndex);
                    setInteractedWithTimeline(true);
                  }}
                ></Timeline>
              ) : null
            ) : (
              <Skeleton height={17} width={300} />
            )}
          </div>
          <div className="bg-dark flex flex-col p-3 rounded-lg h-full mb-5">
            <div className="text-clearblue font-bold text-xl pb-2">SOURCE</div>
            <textarea
              onChange={
                isOwner || editing
                  ? (e) => {
                      if (isOwner && !editing) {
                        setEditing(true);
                      }
                      $editingHTML$.next(e.target.value || "");
                    }
                  : undefined
              }
              className={`w-full h-full filter  transition-all ${
                !isOwner && !editing ? "brightness-50" : "brightness-100"
              }`}
              value={
                editing
                  ? editingHTML
                  : timelineIndex !== undefined &&
                    interactedWithTimeline &&
                    htmlHistory[timelineIndex]
                  ? htmlHistory[timelineIndex].html
                  : info?.html
              }
              onKeyPress={
                isOwner || editing
                  ? undefined
                  : observerHandler($noEditMessage$)
              }
            />
          </div>
        </div>
        <div className="flex flex-col font-bold">
          <div className="mt-6 ml-5 text-2xl">
            {info ? (
              <a href={`${etherscanURL}/address/${info.owner}`}>
                <div className="hover-shiny">
                  {"> "} {info.owner}
                </div>
              </a>
            ) : (
              <Skeleton width={400}></Skeleton>
            )}
          </div>
          <div className="text-4xl bg-clearblue my-4 pl-5">
            {info ? (
              <>
                PAGE {info.page.toString()} {info.x}:{info.y}
              </>
            ) : (
              <Skeleton width={200}></Skeleton>
            )}
          </div>
          <div className="m-4 flex justify-around bg-gradient-to-r from-clearblue to-darkblue p-1 rounded-2xl">
            <div className="flex flex-col text-white p-1  rounded-xl bg-notsodark w-full">
              <div className="flex flex-row w-full justify-around">
                <BigPrice
                  label="Current price"
                  onChange={(price) => setNewPrice(price)}
                  editable={isOwner && editing}
                  price={isOwner && editing ? newPrice : info?.priceEth}
                  ethPrice$={ethPrice$}
                ></BigPrice>
                <a
                  href={
                    info
                      ? `${etherscanURL}/tx/${info.lastTransactionHash}`
                      : undefined
                  }
                >
                  <div className="text-white filter brightness-100 transition-all hover:brightness-75">
                    <BigPrice
                      label="Last sale"
                      price={info ? Number(info.lastSoldPrice) : undefined}
                      ethPrice$={ethPrice$}
                    ></BigPrice>
                  </div>
                </a>
              </div>
              <div className="flex flex-row w-full justify-around my-2">
                {editing ? (
                  !isOwner ? (
                    <Button
                      enabled={forSale}
                      onClick={async () => {
                        if (providerType === ProviderType.Backend) {
                          toast.warn(
                            <>
                              Please{" "}
                              <a href="https://metamask.io/download.html">
                                install MetaMask
                              </a>{" "}
                              to execute transactions
                            </>
                          );
                          return;
                        }
                        if (providerType === ProviderType.MetamaskReadOnly) {
                          toast.warn(
                            "Please connect you MetaMask wallet to execute transactions"
                          );
                          web3Bloc.$requestConnect.next(undefined);
                          return;
                        }
                        try {
                          const promise = cornerContract?.methods
                            .buyTile(
                              page.toString(),
                              x,
                              y,
                              encodeTileHtml(editingHTML)
                            )
                            .send({
                              value: info?.price,
                              from: accounts[0],
                            });
                          await toast.promise(promise, {
                            pending: "Transaction in progress",
                            error: "Transaction failed",
                            success: "Transaction succeeded!",
                          });
                          window.location.reload();
                        } catch (err: any) {
                          console.error(err);
                          if (typeof err === "object" && err?.code === 4001) {
                            toast.warn("Rejected transaction");
                            return;
                          }
                          toast.error(
                            "An unknown error occurred purchasing your tile, try again later or go to our discord for support"
                          );
                        }
                      }}
                      width={400}
                    >
                      CONFIRM PURCHASE
                    </Button>
                  ) : (
                    <Button
                      width={400}
                      enabled={
                        newPrice !== info.priceEth || editingHTML !== info.html
                      }
                      onClick={async () => {
                        const promises = [];
                        try {
                          if (
                            newPrice !== info.priceEth &&
                            newPrice !== undefined
                          ) {
                            promises.push(
                              cornerContract?.methods
                                .setPrice(page, x, y, BigInt(newPrice * 1e18))
                                .send({ from: info.owner })
                            );
                          }
                          if (editingHTML !== info.html) {
                            promises.push(
                              cornerContract?.methods
                                .setHtml(
                                  page,
                                  x,
                                  y,
                                  encodeTileHtml(editingHTML)
                                )
                                .send({ from: info.owner })
                            );
                          }
                          await toast.promise(Promise.all(promises), {
                            pending: "Transaction in progress",
                            error: "Transaction failed",
                            success: "Transaction succeeded!",
                          });
                          window.location.reload();
                        } catch (err: any) {
                          console.error(err);
                          if (typeof err === "object" && err?.code === 4001) {
                            toast.warn("Rejected transaction");
                            return;
                          }
                          toast.error(
                            "An unknown error occurred editing your tile, try again later or go to our discord for support"
                          );
                        }
                      }}
                    >
                      CONFIRM CHANGES
                    </Button>
                  )
                ) : !isOwner ? (
                  <Button
                    onClick={() => {
                      toast(
                        "You can now edit the HTML and confirm your purchase"
                      );
                      setEditing(true);
                      setNewPrice(info?.priceEth || 0);
                      $editingHTML$.next(
                        htmlHistory[timelineIndex!]?.html || ""
                      );
                    }}
                    enabled={forSale}
                    width={400}
                  >
                    {forSale ? "START PURCHASE" : "NOT FOR SALE"}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      toast(
                        "You can now edit the HTML and price, don't forget to confirm your changes"
                      );
                      setEditing(true);
                      setNewPrice(info?.priceEth || 0);
                      $editingHTML$.next(
                        htmlHistory[timelineIndex!]?.html || ""
                      );
                    }}
                    width={400}
                  >
                    EDIT
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-around">
            <Button
              onClick={() => {
                window
                  .open(
                    `http://myblockchaincorner.com/${page.toString()}`,
                    "_blank"
                  )!
                  .focus();
              }}
              width={400}
            >
              See original in myblockchaincorner.com
            </Button>
            <Button
              secondary
              onClick={() => {
                navigator.clipboard.writeText(window.document.URL);
                toast.info("Link copied to clipboard");
              }}
            >
              SHARE
            </Button>
          </div>
          {info ? (
            <div className="grid">
              <div
                id="ez"
                style={{
                  gridColumn: 1,
                  gridRow: 1,
                  transform: "translateX(1000px)",
                }}
              >
                <HTMLHelper
                  onChange={observerHandler($editingHTML$)}
                ></HTMLHelper>
                .
              </div>
              <div
                id="chart"
                style={{
                  gridColumn: 1,
                  gridRow: 1,
                }}
                className="m-4"
              >
                <PriceHistory
                  data={transactions}
                  etherscanURL={etherscanURL}
                ></PriceHistory>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
