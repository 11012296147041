import { ReactNode } from "react";

type Props = {
  secondary?: boolean;
  enabled?: boolean;
  children?: ReactNode;
  width?: number | string;
  onClick?: () => void;
};
export const Button = ({
  children,
  width,
  enabled = true,
  secondary = false,
  onClick,
}: Props) => {
  return (
    <div
      className={`p-0.5 rounded-lg ${
        !enabled ? "bg-darkblue" : "bg-gradient-to-r from-clearblue to-darkblue"
      }`}
      onClick={enabled ? onClick : undefined}
    >
      <div
        style={{ width }}
        className={`flex flex-col items-center filter brightness-100 ${
          enabled
            ? "cursor-pointer hover:brightness-90 active:brightness-75 transition-all " +
              (secondary
                ? "text-clearblue bg-notsodark"
                : "text-white bg-gradient-to-r from-clearblue to-darkblue")
            : "bg-notsodark text-darkblue"
        } justify-around rounded-md px-5 py-1  `}
      >
        {children}
      </div>
    </div>
  );
};
