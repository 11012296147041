import { Tile } from "./Tile";
import { Observable } from "rxjs";
import { useSubscription } from "../util";
import Skeleton from "react-loading-skeleton";
import { TileInfo } from "../types";
import { Button } from "./Button";
import { EthLogo } from "./EthLogo";

type Props = {
  info?: TileInfo;
  isScrolling?: boolean;
  ethPrice$: Observable<{ USD: number }>;
  etherscanURL$: Observable<string>;
};

const DollarPrice = ({
  ethPrice$,
  price,
}: {
  price: number;
  ethPrice$: Observable<{ USD: number }>;
}) => {
  const ethPrice = useSubscription(ethPrice$, undefined);
  return ethPrice ? (
    <>${(price * ethPrice.USD).toFixed(2)}</>
  ) : (
    <Skeleton width={50} />
  );
};
export const TileListing = ({
  info,
  isScrolling = false,
  ethPrice$,
  etherscanURL$,
}: Props) => {
  const etherscanURL = useSubscription(etherscanURL$, "");
  return (
    <div
      className="flex flex-col justify-around items-stretch p-3 rounded-lg bg-dark"
      style={{ width: "490px", height: "370px" }}
    >
      <div className="flex flex-row mt-3 justify-center">
        <Tile
          info={info}
          isScrolling={isScrolling}
          etherscanURL={etherscanURL}
        />
      </div>
      <div className="flex flex-row justify-between align-middle py-3">
        <div className="flex flex-col items-start">
          <div className="text-xl">
            {info ? (
              <>
                Page {info.page.toString()} {info.x}:{info.y}
              </>
            ) : (
              <Skeleton width={70} />
            )}
          </div>
          {info ? (
            <div
              onClick={(e) => {
                e.preventDefault();
                window
                  .open(`${etherscanURL}/address/${info.owner}`, "_blank")!
                  .focus();
              }}
              className="text-center"
            >
              Owner:{" "}
              <a href={`${etherscanURL}/address/${info.owner}`}>
                {info.owner.slice(0, 14)}...
              </a>
            </div>
          ) : (
            <Skeleton width={100} />
          )}
          {info ? (
            <div className="flex flex-row">
              Last sale:
              <EthLogo className="h-6 mx-2" />
              {Number(info.lastSoldPrice.toPrecision(3))} /{" "}
              <DollarPrice
                price={Number(info.lastSoldPrice)}
                ethPrice$={ethPrice$}
              />
            </div>
          ) : (
            <Skeleton width={100} />
          )}
          {info ? (
            <div className="flex flex-row">
              Sold {info.numberOfTransactions} time
              {info.numberOfTransactions > 1 ? "s" : ""}
            </div>
          ) : (
            <Skeleton width={100} />
          )}
        </div>
        <div className="flex flex-col justify-around items-end">
          {info ? (
            <div className="flex flex-row ">
              <EthLogo className="h-6 mr-2" />
              {info.priceEth !== 0 ? (
                <>
                  {info.priceEth.toPrecision(3)} /{" "}
                  <DollarPrice price={info.priceEth} ethPrice$={ethPrice$} />
                </>
              ) : (
                <> Not for sale</>
              )}
            </div>
          ) : (
            <Skeleton width={100} />
          )}
          <Button>{info?.priceEth !== 0 ? "BUY" : "DETAILS"}</Button>
        </div>
      </div>
    </div>
  );
};
