import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Browse } from "./pages/Browse";
import { Web3Context } from "./data_sources/web3";
import { FunctionComponent, useContext, useEffect } from "react";
import { TilesContext } from "./data_sources/tiles";
import { TileOverview } from "./pages/TileOverview";
import { SkeletonTheme } from "react-loading-skeleton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Piping: FunctionComponent = ({ children }) => {
  const web3Bloc = useContext(Web3Context);
  const tilesBloc = useContext(TilesContext);
  useEffect(() => {
    const subscriptions = [
      web3Bloc.cornerContract$.subscribe(tilesBloc.$cornerContract),
      web3Bloc.web3$.subscribe(tilesBloc.$web3),
    ];
    return () => {
      for (const subscription of subscriptions) {
        subscription.unsubscribe();
      }
    };
  }, []);
  return <>{children}</>;
};
export function App() {
  return (
    <Router>
      <SkeletonTheme color="#056F96" highlightColor="#18D4BC">
        <Piping>
          <div className="App bg-notsodark min-h-screen h-screen">
            <Switch>
              <Route exact path="/">
                <Browse />
              </Route>
              <Route
                path="/tile/:page/:x/:y"
                render={({
                  match: {
                    params: { page, x, y },
                  },
                }) => {
                  try {
                    const bp = BigInt(page);
                    const nx = Number(x);
                    const ny = Number(y);
                    if (isNaN(nx) || isNaN(ny)) {
                      throw new Error("Wrong path");
                    }
                    return <TileOverview page={bp} x={nx} y={ny} />;
                  } catch {
                    return <Redirect to="/404" />;
                  }
                }}
              />
              <Route path="/*">
                page not found :{"("} <Link to="/">go back</Link>
              </Route>
            </Switch>
            <ToastContainer theme="dark" position="bottom-right" />
          </div>
        </Piping>
      </SkeletonTheme>
    </Router>
  );
}
