import { Link } from "react-router-dom";
import logo from "../logo-black.svg";
export const Header = () => {
  return (
    <div className="flex flex-row w-full bg-dark">
      <Link to="/">
        <div
          className={
            "flex text-black flex-row items-center w-45 " +
            "bg-gradient-to-r from-clearblue to-darkblue " +
            "p-4 filter brightness-100 transition-all hover:brightness-75"
          }
        >
          <img alt="logo" className="mr-4" width={72} src={logo}></img>
          MY BLOCKCHAIN
          <br />
          CORNER
        </div>
      </Link>
    </div>
  );
};
