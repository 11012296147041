import { useContext, useState } from "react";
import { NEVER } from "rxjs";
import { TileListing } from "../components/TileListing";
import { ethPrice$ } from "../data_sources/ethPrice";
import { TilesContext } from "../data_sources/tiles";
import { Web3Context } from "../data_sources/web3";
import { TileInfo } from "../types";
import { useSubscription } from "../util";
import { FixedSizeGrid, GridChildComponentProps } from "react-window";
import { Link } from "react-router-dom";
import { Header } from "../components/Header";
import { EthLogo } from "../components/EthLogo";
import { Button } from "../components/Button";
import Select from "react-select";
import AutoSizer from "react-virtualized-auto-sizer";

type TileComparator = (a: TileInfo, b: TileInfo) => number;
const tile_asc: TileComparator = (a, b) => {
  if (a.page > b.page) {
    return 1;
  } else if (a.page < b.page) {
    return -1;
  } else if (a.x > b.x) {
    return 1;
  } else if (a.x < b.x) {
    return -1;
  } else if (a.y > b.y) {
    return 1;
  } else if (a.y < b.y) {
    return -1;
  } else {
    return 0;
  }
};

const keyComparator = (
  k: keyof TileInfo | ((a: TileInfo) => TileInfo[keyof TileInfo]),
  reverse = false
): TileComparator => {
  const f = typeof k === "string" ? (a: TileInfo) => a[k as keyof TileInfo] : k;

  return (a, b) => (f(a) > f(b) !== reverse ? 1 : -1);
};
const sortFunctions: Record<string, TileComparator> = {
  tile_asc,
  tile_desc: (a, b) => tile_asc(b, a),
  price_asc: keyComparator("priceEth"),
  price_desc: keyComparator("priceEth", true),
  sales_asc: keyComparator((a) => a.numberOfTransactions),
  sales_desc: keyComparator((a) => a.numberOfTransactions, true),
  last_sale_asc: keyComparator((a) => a.lastSoldPrice),
  last_sale_desc: keyComparator((a) => a.lastSoldPrice, true),
};
const columns = 3;
const Cell = ({
  columnIndex,
  data,
  rowIndex,
  style,
  isScrolling,
}: GridChildComponentProps<TileInfo[]>) => {
  const info = data?.[rowIndex * 3 + columnIndex];
  const web3Bloc = useContext(Web3Context);
  return data === undefined ? (
    <div
      style={style}
      key={rowIndex * 3 + columnIndex}
      className="my-2 flex justify-around items-center"
    >
      <TileListing
        ethPrice$={NEVER}
        etherscanURL$={web3Bloc.etherscanURL$}
      ></TileListing>
    </div>
  ) : info !== undefined ? (
    <Link to={`/tile/${info.page}/${info.x}/${info.y}`}>
      <div
        style={style}
        key={rowIndex * 3 + columnIndex}
        className="my-2 text-white drop-shadow-lg flex justify-around items-center"
      >
        <TileListing
          info={info}
          ethPrice$={ethPrice$}
          isScrolling={isScrolling}
          etherscanURL$={web3Bloc.etherscanURL$}
        ></TileListing>
      </div>
    </Link>
  ) : null;
};
const emptyOrPositive = (v: string) => v === "" || parseInt(v) >= 0;
export const Browse = () => {
  const tilesBloc = useContext(TilesContext);
  const web3Bloc = useContext(Web3Context);

  const [order, setOrder] = useState<keyof typeof sortFunctions>("tile_asc");
  const accounts = new Set(useSubscription(web3Bloc.accounts$, []));

  const [maxEthFilter, setMaxEthFilter] = useState("");
  const [minEthFilter, setMinEthFilter] = useState("");
  const [maxPageFilter, setMaxPageFilter] = useState("");
  const [minPageFilter, setMinPageFilter] = useState("");
  const [forSaleFilter, setForsaleFilter] = useState(false);
  const [ownerFilter, setOwnerFilter] = useState<"all" | "not-me" | "me">(
    "all"
  );

  const tiles = useSubscription(tilesBloc.tiles$, undefined)
    ?.filter((t) => {
      if (maxEthFilter !== "" && t.priceEth > parseInt(maxEthFilter)) {
        return false;
      }
      if (minEthFilter !== "" && t.priceEth < parseInt(minEthFilter)) {
        return false;
      }
      if (maxPageFilter !== "" && t.page > BigInt(maxPageFilter)) {
        return false;
      }
      if (minPageFilter !== "" && t.page < BigInt(minPageFilter)) {
        return false;
      }
      if (forSaleFilter && t.priceEth === 0) {
        return false;
      }
      if (ownerFilter === "not-me" && accounts.has(t.owner)) {
        return false;
      }
      if (ownerFilter === "me" && !accounts.has(t.owner)) {
        return false;
      }
      return true;
    })
    .sort(sortFunctions[order]);
  const rowHeight = 390;
  const minColumnWidth = 520;
  const rows = Math.ceil((tiles?.length || 0) / columns);
  return (
    <div className="h-full flex flex-col">
      <Header />
      <div className="flex flex-grow justify-center items-start text-white">
        <div className="flex flex-col text-lg flex-shrink-0 py-7 pl-10 sticky top-0 ">
          <div className="flex flex-col  my-3 items-center">
            SORT BY
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  cursor: "pointer",
                  borderColor: "#18D4BC",
                  backgroundColor: "#0e353e",
                }),
                menuList: (provided) => ({
                  ...provided,
                  borderColor: "#18D4BC",
                  backgroundColor: "#0e353e",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#fff",
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  background: "#0e353e",
                }),
                option: (provided) => ({
                  ...provided,
                  cursor: "pointer",
                  background: "#0e353e",
                }),
              }}
              defaultValue={{
                value: "tile_asc",
                label: "Page, x, y ascending",
              }}
              onChange={(v) => {
                setOrder(v?.value as any);
              }}
              options={
                [
                  {
                    label: "Page, x, y ascending",
                    value: "tile_asc",
                  },
                  {
                    label: "Page, x, y descending",
                    value: "tile_desc",
                  },
                  { value: "price_desc", label: "Expensive first" },
                  { value: "price_asc", label: "Cheapest first" },
                  { value: "last_sale_desc", label: "Highest last sale first" },
                  { value: "last_sale_asc", label: "Lowest last sale first" },
                  { value: "sales_desc", label: "Most times sold first" },
                  { value: "sales_asc", label: "Least times sold first" },
                ] as any
              }
            />
          </div>
          <div className="flex flex-row my-3 items-center">
            Price from <EthLogo className="h-6 mx-1" />
            <input
              className="w-10  bg-darkblue rounded-md mr-1"
              onChange={(e) =>
                emptyOrPositive(e.target.value)
                  ? setMinEthFilter(e.target.value)
                  : null
              }
              type="number"
            ></input>
            to
            <EthLogo className="h-6 mx-1" />
            <input
              className="w-10  bg-darkblue rounded-md"
              onChange={(e) =>
                emptyOrPositive(e.target.value)
                  ? setMaxEthFilter(e.target.value)
                  : null
              }
              type="number"
            ></input>
          </div>
          <div className="flex flex-row my-3">
            Page from
            <input
              className="w-10  bg-darkblue rounded-md mx-1"
              onChange={(e) =>
                emptyOrPositive(e.target.value)
                  ? setMinPageFilter(e.target.value)
                  : null
              }
              type="number"
            ></input>{" "}
            to{" "}
            <input
              className="w-10  bg-darkblue rounded-md mx-1"
              onChange={(e) =>
                emptyOrPositive(e.target.value)
                  ? setMaxPageFilter(e.target.value)
                  : null
              }
              type="number"
            ></input>
          </div>
          <div className="my-3">
            <Button
              secondary={forSaleFilter}
              onClick={() => setForsaleFilter(!forSaleFilter)}
            >
              {forSaleFilter ? "SEE ALL TILES" : "SEE ONLY FOR SALE"}
            </Button>
          </div>
          <div className="my-3 flex flex-col items-center">
            OWNER
            <div className=" flex flex-row">
              <Button
                secondary={ownerFilter === "all"}
                onClick={
                  ownerFilter !== "all"
                    ? () => setOwnerFilter("all")
                    : undefined
                }
              >
                ALL
              </Button>
              <div className="mx-2">
                <Button
                  secondary={ownerFilter === "not-me"}
                  onClick={
                    ownerFilter !== "not-me"
                      ? () => setOwnerFilter("not-me")
                      : undefined
                  }
                >
                  OTHERS
                </Button>
              </div>
              <Button
                secondary={ownerFilter === "me"}
                onClick={
                  ownerFilter !== "me" ? () => setOwnerFilter("me") : undefined
                }
              >
                ME
              </Button>
            </div>
          </div>
        </div>
        <div className="pl-7 flex-grow h-full">
          <AutoSizer>
            {({ height, width }) => {
              const columnCount = Math.floor(width / minColumnWidth);
              return (
                <FixedSizeGrid
                  columnCount={columnCount}
                  columnWidth={width / columnCount - 10}
                  rowHeight={rowHeight}
                  rowCount={tiles !== undefined ? rows : 3}
                  height={height}
                  width={width}
                  itemData={tiles}
                  overscanRowCount={3}
                  useIsScrolling={true}
                >
                  {Cell}
                </FixedSizeGrid>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};
