import { useState, useEffect, useMemo, useRef } from "react";
import { NextObserver, Observable } from "rxjs";

export function useSubscription<T, K>(
  o: Observable<T> | (() => Observable<T>),
  initial: K,
  error?: (err: any) => void,
  complete?: () => void,
  changer: any[] = [o, error, complete]
): T | K {
  const observable = useMemo(
    () => (typeof o === "function" ? o() : o),
    changer
  );
  const ref = useRef<T | K>(initial);
  const forceUpdate = useState(0)[1];
  useEffect(() => {
    const subscription = observable.subscribe({
      next: (n) => {
        ref.current = n;
        forceUpdate(increment);
      },
      error,
      complete,
    });
    return () => subscription.unsubscribe();
  }, changer);
  return ref.current;
}
function increment(n: number): number {
  return (n + 1) % 1000000;
}

export function getOrDefault<K, V>(map: Map<K, V>, key: K, def: () => V) {
  let a = map.get(key);

  if (a === undefined) {
    return def();
  } else {
    return a;
  }
}
export function getOrSetDefault<K, V>(map: Map<K, V>, key: K, def: () => V) {
  let a = map.get(key);

  if (a === undefined) {
    const d = def();
    map.set(key, d);
    return d;
  } else {
    return a;
  }
}
export function updateMap<K, V>(
  map: Map<K, V>,
  key: K,
  def: () => V,
  f: (old: V) => V
) {
  let a = getOrDefault(map, key, def);

  map.set(key, f(a));
}
export const range = (n: number) => [...Array(n).keys()];

export const notUndefined = <T>(i: T | undefined): i is T => i !== undefined;

export const observerHandler =
  <T>(o: NextObserver<T>) =>
  (item: T) =>
    o.next(item);

export const tileId = (
  page: bigint | number,
  x: bigint | number,
  y: bigint | number
) => BigInt(x) + BigInt(y) * BigInt(4) + BigInt(page) * BigInt(16);

export const fromTileId = (id: BigInt) => {
  const anyid: any = id;
  const page = anyid / BigInt(16);
  const rest = anyid % BigInt(16);
  const y = rest / BigInt(4);
  const x = rest % BigInt(4);
  return { page: page, x: Number(x), y: Number(y) };
};
export const getLast = <T>(arr: T[]) => arr[arr.length - 1];
export const compareMapper =
  <T>(f: (i: T) => number) =>
  (a: T, b: T) => {
    const fa = f(a);
    const fb = f(b);
    return fa > fb ? 1 : fb > fa ? -1 : 0;
  };
