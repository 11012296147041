import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { tileHeight, tileWidth } from "../constants";
import { TileInfo } from "../types";
import { strToBase64 } from "../util/base64";

type Props = {
  info: TileInfo | undefined;
  isScrolling?: boolean;
  width?: number;
  etherscanURL: string;
};

export const Tile = ({
  info,
  isScrolling = false,
  width = tileWidth,
  etherscanURL,
}: Props) => {
  const ref = useRef(null);
  const zoom = width / tileWidth;
  const height = tileHeight * zoom;

  if (!info || (isScrolling && ref.current === null)) {
    return <Skeleton width={width} height={height} />;
  }

  let { html, owner, x, y, page } = info;
  return (
    <div style={{ width, height }}>
      {html !== "" ? (
        <iframe
          ref={ref}
          title={"" + page + x + y}
          className="bg-gradient-to-br from-darkblue to-clearblue"
          style={{
            width: tileWidth,
            height: tileHeight,
            transform: `scale(${zoom}) translate(${
              (width - tileWidth) / (zoom * 2)
            }px, ${(height - tileHeight) / (zoom * 2)}px)`,
          }}
          sandbox="allow-scripts allow-same-origin"
          src={"data:text/html;charset=utf-8;base64," + strToBase64(html)}
        />
      ) : (
        <div
          ref={ref}
          className="bg-gradient-to-br from-darkblue to-clearblue flex justify-around items-center"
          style={{ width, height }}
        >
          <div className="bg-white m-11 text-black p-5 rounded-md shadow-lg">
            Hello{" "}
            <a href={`${etherscanURL}/address/${info.owner}`}>
              {owner.slice(0, 10)}...
            </a>
            , your tile is looking quite empty, adding some cool stuff to it is
            now easier than ever! Click here!
          </div>
        </div>
      )}
    </div>
  );
};
