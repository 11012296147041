import {
  catchError,
  EMPTY,
  from,
  interval,
  Observable,
  shareReplay,
  startWith,
  switchMap,
} from "rxjs";

export const ethPrice$ = new Observable<{ USD: number }>((subscriber) => {
  const subscription = interval(10000)
    .pipe(
      startWith(0),
      switchMap(() => {
        return from(
          fetch(
            "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
          ).then((res) => res.json() as Promise<{ USD: number }>)
        ).pipe(catchError(() => EMPTY));
      })
    )
    .subscribe(subscriber);
  return () => subscription.unsubscribe();
}).pipe(shareReplay(1));
