import {
  combineLatest,
  concat,
  debounceTime,
  from,
  fromEventPattern,
  map,
  NEVER,
  NextObserver,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  take,
  startWith,
  distinctUntilChanged,
  catchError,
  EMPTY,
} from "rxjs";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { NodeStyleEventEmitter } from "rxjs/internal/observable/fromEvent";
import { Chain } from "../types";
import { Contract } from "web3-eth-contract";
import { cornerAbi } from "../constants";
import { createContext } from "react";

export type Provider =
  | (ConstructorParameters<typeof Web3>[0] & NodeStyleEventEmitter)
  | string;

export enum ProviderType {
  Backend,
  MetamaskReadOnly,
  MetamaskReadWrite,
}
export const isReadOnly = (pt: ProviderType) =>
  [ProviderType.MetamaskReadOnly, ProviderType.Backend].includes(pt);

export class Web3Bloc {
  public readonly $requestConnect: NextObserver<any>;
  public readonly provider$: Observable<Provider>;
  public readonly providerType$: Observable<ProviderType>;
  public readonly web3$: Observable<Web3>;
  public readonly chain$: Observable<Chain>;
  public readonly cornerContract$: Observable<Contract>;
  public readonly messages$: Observable<string>;
  public readonly accounts$: Observable<string[]>;
  public readonly etherscanURL$: Observable<string>;

  constructor() {
    const $requestConnect$ = new Subject();
    const $messages$ = new Subject<string>();
    this.messages$ = $messages$;
    this.$requestConnect = $requestConnect$;

    // switchmap concat
    const providerAndType$ = from([
      of<[Provider, ProviderType]>([
        "wss://mainnet.infura.io/ws/v3/fcd40da7d2424f90ba99e8b42d51f7ec",
        ProviderType.Backend,
      ]),
      $requestConnect$.pipe(
        startWith(undefined),
        switchMap(
          () =>
            new Observable<[Provider, ProviderType]>((subscriber) => {
              (async () => {
                const provider: any = await detectEthereumProvider();
                if (!provider) {
                  return;
                }
                subscriber.next([provider, ProviderType.MetamaskReadOnly]);
                await provider.request({ method: "eth_requestAccounts" });
                subscriber.next([provider, ProviderType.MetamaskReadWrite]);
              })().catch(() => {
                // don't care
              });
            })
        )
      ),
    ]).pipe(
      switchMap((a) =>
        a.pipe(
          catchError(() => {
            setTimeout(() => {
              $requestConnect$.next(undefined);
            }, 1000);
            return EMPTY;
          })
        )
      ),
      distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
      debounceTime(100),
      shareReplay(1)
    );

    this.provider$ = providerAndType$.pipe(map(([p]) => p));

    this.providerType$ = providerAndType$.pipe(map(([_, pt]) => pt));

    this.web3$ = this.provider$.pipe(
      map((p) => new Web3(p)),
      shareReplay(1)
    );

    this.chain$ = new Observable<Chain>((subscriber) => {
      const subscription = concat(
        this.web3$.pipe(
          switchMap((web3) => from(web3.eth.getChainId())),
          take(1)
        ),
        this.provider$.pipe(
          switchMap<Provider, Observable<string>>((provider) => {
            if (typeof provider !== "string") {
              return fromEventPattern(
                (handler) => {
                  provider.addListener("networkChanged", handler);
                },
                (handler) => {
                  provider.removeListener("networkChanged", handler);
                }
              );
            } else {
              return NEVER;
            }
          }),
          map((n) => parseInt(n))
        )
      )
        .pipe(
          map((id) => {
            switch (id) {
              case 1:
                return Chain.Mainnet;
              case 3:
                return Chain.Ropsten;
            }
            throw new Error("Unknown chain id " + id);
          })
        )
        .subscribe(subscriber);
      return subscription;
    }).pipe(shareReplay(1));

    this.cornerContract$ = combineLatest([this.chain$, this.web3$]).pipe(
      map(([chain, web3]) => {
        const address = getContractAddress(chain);
        return new web3.eth.Contract(cornerAbi, address);
      })
    );
    this.accounts$ = this.web3$.pipe(
      switchMap((w3) => from(w3.eth.getAccounts()))
    );
    this.etherscanURL$ = this.chain$.pipe(
      map((chain) => {
        switch (chain) {
          case Chain.Mainnet:
            return "https://etherscan.io";
          case Chain.Ropsten:
            return "https://ropsten.etherscan.io";
        }
      })
    );
  }
}

function getContractAddress(chain: Chain) {
  switch (chain) {
    case Chain.Mainnet:
      return "0x8c051c68d9601771ce96d4c9e971985aede480f7";
    case Chain.Ropsten:
      return "0xfeba05ed61f58a1713c0a481792b1efad4e2d215";
  }
  throw new Error("Unknown network" + chain);
}

export const Web3Context = createContext(new Web3Bloc());
