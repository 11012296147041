import Skeleton from "react-loading-skeleton";
import { Observable } from "rxjs";
import { useSubscription } from "../util";
import { EthLogo } from "./EthLogo";

type Props = {
  price?: number;
  label: string;
  ethPrice$: Observable<{ USD: number }>;
  editable?: boolean;
  onChange?: (val: number | undefined) => any;
};
export const BigPrice = ({
  price,
  ethPrice$,
  label,
  editable = false,
  onChange,
}: Props) => {
  const ethPrice = useSubscription(ethPrice$, undefined);

  return (
    <div className=" flex flex-row m-2">
      <EthLogo />
      <div className="flex flex-col mx-4">
        <div>{label}</div>
        <div className="text-2xl">
          {editable ? (
            <div>
              <input
                className="w-20  bg-darkblue rounded-md mx-1"
                value={price}
                onChange={
                  onChange
                    ? (e) => {
                        if (e.target.value === "") {
                          onChange(undefined);
                          return;
                        }
                        const n = parseFloat(e.target.value);
                        if (isFinite(n) && !isNaN(n)) {
                          onChange(n);
                        }
                      }
                    : undefined
                }
                type="number"
              ></input>
              / $
              {price === undefined ? (
                "-"
              ) : ethPrice ? (
                <>{(price * ethPrice.USD).toFixed(2)}</>
              ) : (
                <Skeleton></Skeleton>
              )}
            </div>
          ) : price !== undefined ? (
            price === 0 ? (
              <> Not for sale</>
            ) : (
              <div>
                {price} / $
                {ethPrice ? (
                  <>{(price * ethPrice.USD).toFixed(2)}</>
                ) : (
                  <Skeleton></Skeleton>
                )}
              </div>
            )
          ) : (
            <Skeleton width={200}></Skeleton>
          )}
        </div>
      </div>
    </div>
  );
};
