import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { ScriptableContext } from "chart.js";
import { clearblue, darkblue } from "../constants";
export type TimelinePoint = {
  date: Date;
  owner: string;
};
type Props = {
  data: TimelinePoint[];
  selectedIndex: number;
  onChange: (index: number) => any;
};
export const Timeline = ({ data, selectedIndex, onChange }: Props) => {
  const points = data.map(({ date }, i) => ({
    x: date.getTime(),
    y: 0,
    selectedRange: selectedIndex >= i,
  }));
  return (
    <Line
      getElementAtEvent={([element]: any[]) => {
        if (element && element.index !== undefined) {
          onChange(element.index);
        }
      }}
      height={17}
      data={{
        datasets: [
          {
            data: points,
            pointRadius: 7,
            pointHitRadius: 17,
            hoverRadius: 5,

            backgroundColor: (ctx: ScriptableContext<"line">) => {
              if ((ctx as any).type !== "data") {
                return darkblue;
              }
              return points[ctx.dataIndex].selectedRange ? clearblue : darkblue;
            },
            segment: {
              borderColor: (ctx) =>
                points[ctx.p1DataIndex].selectedRange ? clearblue : darkblue,
            },
            parsing: false,
          },
        ],
      }}
      options={{
        interaction: { mode: "nearest", axis: "x", intersect: true },
        onHover(e, elements) {
          const style = (e.native?.target as HTMLElement | undefined)?.style;
          if (style) {
            style.cursor = elements[0] ? "pointer" : "default";
          }
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            position: "nearest",
            displayColors: false,
            backgroundColor: darkblue,
            caretSize: 0,
            xAlign: "right",
            yAlign: "center",
            callbacks: {
              label: (item) => {
                const { owner } = data[item.dataIndex];
                return `Author: ${owner}`;
              },
            },
          },
        },
        scales: {
          y: { display: false },
          x: {
            type: "timeseries",
            ticks: { color: clearblue, source: "data" },
            time: { minUnit: "day" },
          },
        },
      }}
    />
  );
};
